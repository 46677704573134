<template>
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> Revenue List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Revenue</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
      <b-card>
      <b-row>
        <b-col lg="3">
          <b-form-group
            label="Sort"
            label-for="sort-by-select"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-50"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
  
              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-10"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="6"></b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
            label="Initial sort"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-2"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc', 'last']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
  
       
 
      
    </b-row>
  <b-row>
        <b-col sm="1" md="1">
          <b-form-group
          
            label-for="per-page-select"
            
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="8" >
            <b-form-group
            v-model="sortDirection"
            label="Filter On"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="3"
            label-align-sm="center"
            label-size="sm"
            class="mb-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox value="name">Name</b-form-checkbox>
              <b-form-checkbox value="vendor_id">Vendor ID</b-form-checkbox>
              <b-form-checkbox value="total_book">Total</b-form-checkbox>
              <b-form-checkbox value="total_pending">Pending</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" >
          <b-form-group
            
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
  
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
      </b-row>
  
      <!-- Main table element -->
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        :busy.sync="isBusy"
      >
       <!--  <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template> -->
  
        <template #cell(actions)="row">
         <!--  <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1"> -->
          <b-button size="sm" @click="info(row.item.vendor_id, row.index, $event.target)" class="mr-1">
           View all 
          </b-button>
         <!--  <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button> -->
        </template>
  
        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <b-col sm="2" md="2" class="my-1 float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @change="handlePageChange"
            prev-text="Prev"
            next-text="Next"
          ></b-pagination>
        </b-col>
    </b-card>
      <!-- Info modal -->
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </b-container>
  </template>
  
  <script>
   import axios from "axios";

    export default {
      data() {
        return {
        /*   items: [
            { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
            { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
            {
              isActive: false,
              age: 9,
              name: { first: 'Mini', last: 'Navarro' },
              _rowVariant: 'success'
            },
            { isActive: false, age: 89, name: { first: 'Geneva', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'Jami', last: 'Carney' } },
            { isActive: false, age: 27, name: { first: 'Essie', last: 'Dunlap' } },
            { isActive: true, age: 40, name: { first: 'Thor', last: 'Macdonald' } },
            {
              isActive: true,
              age: 87,
              name: { first: 'Larsen', last: 'Shaw' },
              _cellVariants: { age: 'danger', isActive: 'warning' }
            },
            { isActive: false, age: 26, name: { first: 'Mitzi', last: 'Navarro' } },
            { isActive: false, age: 22, name: { first: 'Genevieve', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'John', last: 'Carney' } },
            { isActive: false, age: 29, name: { first: 'Dick', last: 'Dunlap' } }
          ], */
          items:[],
          fields: [
            { key: 'name', label: 'Vendor name', sortable: true, sortDirection: 'desc' },
            { key: 'vendor_id', label: 'Vendor Id', sortable: true, class: 'text-center' },
            { key: 'total_book', label: 'Total Booked', sortable: true, class: 'text-center' },
            { key: 'total_invoice', label: 'Total Invoice', sortable: true, class: 'text-center' },
            { key: 'total_invoiced', label: 'VB Total Invoice', sortable: true, class: 'text-center' },
            { key: 'total_pending', label: 'VB Invoice Pending', sortable: true, class: 'text-center' },
            { key: 'actions', label: 'Actions' }
          /*   {
              key: 'isActive',
              label: 'Is Active',
              formatter: (value, key, item) => {
                key,item
                return value ? 'Yes' : 'No'
              },
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true
            },
            { key: 'actions', label: 'Actions' } */
          ],
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 20, 50, { value: 100, text: "All" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
          infoModal: {
            id: 'info-modal',
            title: '',
            content: ''
          },
          isBusy:false,
        }
      },
      computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            })
        }
      },
     
      methods: {
        info(item, index, button) {
            item, index, button
         /*  this.infoModal.title = `Row index: ${index}`
          this.infoModal.content = JSON.stringify(item, null, 2)
          this.$root.$emit('bv::show::modal', this.infoModal.id, button) */
          this.$router.push('/finance/list_revenue/'+item)
        },
        resetInfoModal() {
          this.infoModal.title = ''
          this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
       /*  async handlePageChange()
        {
//alert(this.currentPage)
        }, */
        async revenue_list()
        {
            this.isBusy = true
             axios.post("/admin/revenue_list").then((resp) => {
        this.items = [];
        this.totalRows = resp.data.length
        this.isBusy = false;
            for(var i=0; i < resp.data.length;i++)
            {
                this.items.push({
                    name:resp.data[i].first_name+ ' '+resp.data[i].last_name,
                    vendor_id:resp.data[i].vendor_auto_id,
                    total_book:resp.data[i].total_booking,
                    total_invoice:resp.data[i].total_invoice,
                    total_invoiced:resp.data[i].total_vb_invoice,
                    total_pending:resp.data[i].total_paid_vb_invoice,
                });

            }
        });
        }
      },
      mounted() {
        // Set the initial number of items
        this.revenue_list();
        
      },
    }
  </script>